import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { showerScript } from '../utils/JQuery/show.js';

const Services = () => {
  const [services, setServices] = useState([]);
  const [homepage, setHomepage] = useState([]);

  useEffect(() => {
    showerScript();

    // Function to fetch services from the API
    const fetchServices = async () => {
      try {
        const version = await fetch('https://glowydent-asset-manager.cuid.fr//json_manager/getVersions.php');
        const versionData = await version.json();        
        const response = await fetch(`/data/performance_${versionData.performance}.json`);
        const data = await response.json();
        const homepageResponse = await fetch(`/data/homepage_${versionData.homepage}.json`);
        const homepageData = await homepageResponse.json();
        setServices(data); // Update state with fetched services
        setHomepage(homepageData); // Update state with fetched services
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices(); // Call the function when the component mounts
  }, []); // Empty dependency array ensures the effect runs once on mount

  return (
    <section className="full-h shower secondary-color" id="services">
      <div className="full central-content">
        <div className="content central-text">
          <h2 className="white-text">Nos services</h2>
          <div id="bigpoints" className="centralizer transitioner hiding mobileblock flexible">
            {services.map((service) => (
              <nav className="bigpoint" key={service.id}>
                <div className="circle invert">
                  <img src={service.symbol} alt={service.name} />
                </div>
                <strong className="white-text">{service.name}</strong>
              </nav>
            ))}
          </div>
        </div>

        <p className="transitioner hiding white-text">
          {homepage.ourServices}
        </p>
      </div>
    </section>
  );
};

export default Services;
