import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Booking from './Booking';
import Gallery from './Gallery';
import Services from './Services';
import Prices from './Prices';
import Galleries from './Gallery';
import Loader from './Loader';
import { showerScript } from '../utils/JQuery/show.js';
import $ from 'jquery';

const Dashboard = () => {

  /*----API ---*/

  const [homeData, setHomeData] = useState([]);
  const [websiteData, setWebsiteData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    showerScript();

    $(window).scrollTop(0);


    const fetchData = async (e) => {

      try {

        const version = await fetch('https://glowydent-asset-manager.cuid.fr//json_manager/getVersions.php'); //This API it's a PHP code return a jSon with the number latest version of json file data website_XXXX.json
        const versionData = await version.json();
        const website = await fetch('/data/website_'+versionData.website+'.json');
        const homepage = await fetch('/data/homepage_'+versionData.homepage+'.json');
        const result = await homepage.json();
        const result2 = await website.json();
        setHomeData(result);
        setWebsiteData(result2);
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };




    fetchData();
  }, []);


  

  /*-----API-----*/


  return (

    

    <React.Fragment>

<Helmet>
        <title>{homeData.title}</title>
        <meta name="description" content={homeData.description} />
      </Helmet>

      {/*
 <section id="dashboard-part">  

              <div className="container">
                <div className="padder">
                <form onSubmit={handleSubmit}>

                        <Helmet>
                  <title>Page d'accueil</title>
                  <meta name="description" content="Le dashboard" />
                  <link rel="canonical" href="https://www.example.com/my-page" />
                </Helmet>

                        <h1>Tableau de Bord:</h1>
                <h2>Bonjour {userdata.login}</h2>
                <label>
                  Login:
                  <input
                    type="text"
                    name="login"
                    value={editableValue.login}
                    onChange={handleInputChange}
                  />
                </label>
                <br />
                
                <button type="submit">Submit</button>
              </form>
              </div>
              </div>

    

    </section> 

<section className="full-h secondary-color" id="aboutus">

                        

                        <div className="flexible full">

                        <div className="image hiddenformobile" style={{ backgroundImage: `url(${homeData.aboutUsImage})` }}></div>

                        <div className="content">
                        <div className="padder shower ">
                          
                        <h2 className="transitioner hiding">{homeData.aboutUsTitle}</h2>
                        <div className="content-text transitioner hiding">{homeData.aboutUs}</div>
                        </div>

                      
                        
                        
                        
                        </div>

                        
            </div>
            
            </section>

            <Services />
            <Prices/>
            <Galleries />
            <Booking />

            */}
            
    </React.Fragment>

    
  );
  }



export default Dashboard