import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from './Loader';
import PageLoader from './PageLoader';
import Booking from './Booking';
import $ from 'jquery';
import Faqs from './Faq';
import { Helmet } from 'react-helmet';

const Page = () => {
  const [page, setPageData] = useState({});
  const [website, setWebsiteData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { name } = useParams();

  useEffect(() => {
    $(window).scrollTop(0);
    $(".hiding").removeClass("hiding"); // Fix class selector

    const fetchData = async () => {
      setLoading(true);
      try {
        const version = await fetch('https://glowydent-asset-manager.cuid.fr//json_manager/getVersions.php');
        const versionData = await version.json();
        const websiteResponse = await fetch(`/data/website_${versionData.website}.json`);
        const pageResponse = await fetch(`/data/page_${name}_${versionData[`page_${name}`]}.json`);
        const pageData = await pageResponse.json();
        const websiteData = await websiteResponse.json();
        setPageData(pageData);
        setWebsiteData(websiteData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [name]);

  if (loading) {
    return (
      <section id="dashboard-part">
        <div className="container">
          <div className="padder">
            <PageLoader />
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const renderContent = () => {
    if (name === "reservation") {
      return (
        <section id="dashboard-part" className="introduction-effect">
          <div className="container">
            <div className="padder">
              <Booking />
            </div>
          </div>
        </section>
      );
    } else if (name === "faq") {
      return (
        <section id="dashboard-part" className="introduction-effect">
          <div className="container">
            <div className="padder">
              <Faqs />
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <section id="dashboard-part" className="introduction-effect">
          <div className="container">
            <div className="padder">
              <div id="page-content" dangerouslySetInnerHTML={{ __html: page.content }} />
            </div>
          </div>
        </section>
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>{page.title || "Default Title"}</title>
        <meta name="description" content={page.description || "Default description"} />
      </Helmet>
      {renderContent()}
    </>
  );
};

export default Page;
