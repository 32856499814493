import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, useParams } from 'react-router-dom';
import $ from 'jquery';


const Banner = () => {
  const location = useLocation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { name } = useParams();
  const [title, setTitle] = useState("");
  const [homeData, setHomeData] = useState([]);

  // Handle default name value
  const pageName = name || "homepage";


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setData({});

      try {

        const version = await fetch('https://glowydent-asset-manager.cuid.fr//json_manager/getVersions.php'); //This API it's a PHP code return a jSon with the number latest version of json file data website_XXXX.json
        const versionData = await version.json();
        const homepage = await fetch('/data/homepage_'+versionData.homepage+'.json');

        if(pageName != "homepage"){
        const response = await fetch(`/data/page_${name}_${versionData[`page_${name}`]}.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');

        }

        const result = await response.json();
        setData(result);
        }



        const resultHomeData = await homepage.json();
        setHomeData(resultHomeData);

        setError(null);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [name]);

  useEffect(() => {
    if (data.title) {
      setTitle(`<h1 class="white-text introduction-effect">${data.title}</h1>`);
    }
  }, [data]);



  const getImagePath = (imageName) => {
    return `/media/image/${imageName}`;
  };




  return (
    <>




      {location.pathname === '/' && (
        <div id="banner">
          <div className="padder">
          <div id="bigtitle">
            <div className="blockable introduction-up-effect">
              <img className="logo invert" src={getImagePath('icons/logo.png')} alt="Logo"/>
              <h1 className="white-text typingText">Soyez prêt.es , nous arrivons bientôt!</h1>
            </div>
          </div>

          <div id="early-bird" class="organizer introduction-effect "><div class="padder"><div class="flexible mobileblock">
            
            
            <div id="early-bird-pic" className="column organizer"><img className="logo invert" src="/media/image/icons/bird.png" /></div>
          
          <div id="early-bird-info" className="column"><h2 className="white-text">Offre Early Bird à partir<br></br> de 59,99€</h2><a href="https://my.weezevent.com/something-new-conference?_gl=1*1nhxmty*_gcl_au*MjkwNDkxNTMyLjE3MjM5MzM3OTUuNTI3ODY0NDMuMTcyMzkzMzg1Mi4xNzIzOTMzODUx*_ga*MTY2OTc4ODI2Mi4xNzIzOTMzNzk1*_ga_39H9VBFX7G*MTcyMzk0NjEyNS4zLjEuMTcyMzk0Njg5NS44LjAuMA"><button>Voir l'offre</button></a></div>
          
          </div>
          
          </div>
          </div>

          <p className="central-text white-text">
        Powered by <a href="https://aienova.com">
          <img className="logo-mini invert" src={getImagePath('icons/aienova.png')} alt="Aienova" />
        </a> Nothing to something
      </p>
         {/* <video src={homeData.bannerVideo} autoPlay loop muted id="bgvid"></video> */}
        </div>
        </div>
        
      )}

      {location.pathname !== '/' && (
        <div id="simple-banner" style={{ backgroundImage: `url(${homeData.bannerImage})` }}>
          <div id="bigtitle">
            <div className="padder">
              {/* <div className="banner-title" dangerouslySetInnerHTML={{ __html: title }}></div> */}

              <div className="banner-title"><h1>Coming Soon, become higher yourself</h1></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
